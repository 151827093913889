export const refs = {
  openAuthBtn: document.querySelector('button.auth__modal-open-js'),
  closeAutBtn: document.querySelector('button.auth__modal-close-js'),
  modalAuth: document.querySelector('div.auth__modal-js'),
  signUpBtn: document.querySelector('.form__btn-up'),
  signInBtn: document.querySelector('.form__btn-in'),
  signOutBtn: document.querySelector('.form__btn-out'),
  submitBtn: document.querySelector('.form__btn'),
  form: document.querySelector('.form'),
  userBtn: document.querySelector('.auth-btn__user-js'),
  logOut: document.querySelector('.auth-btn__logOut-js'),
  formInputs: document.querySelector('.form__render'),
  inputName: document.querySelector('.form__input-name'),
  labelName: document.querySelector('.form__label-name'),
  inputMail: document.querySelector('.form__input-mail'),
  labelMail: document.querySelector('.form__label-mail'),
  inputPassword: document.querySelector('.form__input-password'),
  labelPassword: document.querySelector('.form__label-password'),
  formAuth: document.querySelector('.form'),
  openModalBtn: document.querySelector('[data-action="open-modal"]'),
  closeModalBtn: document.querySelector('[data-action="close-modal"]'),
  registrationBtn: document.querySelector('[data-action="registration"]'),
  homePainting: document.querySelector('.nav__home'),
  shoppingListPainting: document.querySelector('.nav__shopping'),
  exitBtn: document.querySelector('.exit_btn'),
  userName: document.querySelector('.modal__item'),
  userNameModal: document.querySelector('.modal__user'),
  userText: document.querySelector('.auth-btn__user-text'),
  shopingListBtn: document.querySelector('.nav__shopping'),
  homeBtn: document.querySelector('.nav__home'),
  userIconArrowUse: document.querySelector(
    '.auth-btn__icon-user-arrow-down use'
  ),
  userIconArrow: document.querySelector('.auth-btn__icon-user-arrow-down'),
  userIconUser: document.querySelector('.auth-btn__icon-user'),
  userTextMobMenu: document.querySelector(`.modal__user p`),
};
