export const supporters = [
  {
    title: 'Save the Children',
    url: 'https://www.savethechildren.net/what-we-do/emergencies/ukraine-crisis',
    img: './save-the-children@1x.png',
    imgRetinaTwo: './save-the-children@2x.png',
    imgRetinaThree: './save-the-children@3x.png',
  },
  {
    title: 'Project HOPE',
    url: 'https://www.projecthope.org/country/ukraine/',
    img: './project-hope@1x.png',
    imgRetinaTwo: './project-hope@2x.png',
    imgRetinaThree: './project-hope@3x.png',
  },
  {
    title: 'International Medical Corps',
    url: 'https://internationalmedicalcorps.org/country/ukraine/',
    img: './international-medical-corps@1x.png',
    imgRetinaTwo: './international-medical-corps@2x.png',
    imgRetinaThree: './international-medical-corps@3x.png',
  },
  {
    title: 'Medicins Sans Frontieres',
    url: 'https://www.msf.org/ukraine',
    img: './medicins-sans-frontieres@1x.png',
    imgRetinaTwo: './medicins-sans-frontieres@2x.png',
    imgRetinaThree: './medicins-sans-frontieres@3x.png',
  },
  {
    title: 'RAZOM',
    url: 'https://www.razomforukraine.org/',
    img: './razom@1x.png',
    imgRetinaTwo: './razom@2x.png',
    imgRetinaThree: './razom@3x.png',
  },
  {
    title: 'Action against hunger',
    url: 'https://www.actionagainsthunger.org/location/europe/ukraine/',
    img: './action-against-hunger@1x.png',
    imgRetinaTwo: './action-against-hunger@2x.png',
    imgRetinaThree: './action-against-hunger@3x.png',
  },
  {
    title: 'World vision',
    url: 'https://www.wvi.org/emergencies/ukraine',
    img: './world-vision@1x.png',
    imgRetinaTwo: './world-vision@2x.png',
    imgRetinaThree: './world-vision@3x.png',
  },
  {
    title: 'Serhiy Prytula Charity Foundation',
    url: 'https://prytulafoundation.org/en',
    img: './serhiy-prytula-charity-foundation@1x.png',
    imgRetinaTwo: './serhiy-prytula-charity-foundation@2x.png',
    imgRetinaThree: './serhiy-prytula-charity-foundation@3x.png',
  },
  {
    title: 'UNITED24',
    url: 'https://u24.gov.ua/uk',
    img: './united24@1x.png',
    imgRetinaTwo: './united24@2x.png',
    imgRetinaThree: './united24@3x.png',
  },
];
