import { initializeApp } from 'firebase/app';
import { refs } from '../refs';
import { getDatabase, set, ref, update } from 'firebase/database';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { onSignUpBtn } from './modal-window-authFirebase';
import Notiflix from 'notiflix';
Notiflix.Notify.init({
  timeout: 1000,
  cssAnimationStyle: 'from-top',
  position: 'center-top',
});
import { readShoppingList } from './firebaseDatabase';
import { renderCardOfBooks } from '../shopping_list/shopping-list';
import { userName } from './firebaseDatabase';

const firebaseConfig = {
  apiKey: 'AIzaSyAmjMPhgeiPnKuRTAY8vypkpT4j7HmPfug',
  authDomain: 'fu11stack-book-project.firebaseapp.com',
  databaseURL:
    'https://fu11stack-book-project-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'fu11stack-book-project',
  storageBucket: 'fu11stack-book-project.appspot.com',
  messagingSenderId: '110270361117',
  appId: '1:110270361117:web:304c337163371a2e8920f4',
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
export const auth = getAuth();

export const signUp = (email, password, username) => {
  createUserWithEmailAndPassword(auth, email, password)
    .then(userCredential => {
      const user = userCredential.user;

      set(ref(database, '/users' + user.uid), {
        username: username,
        email: email,
        password: password,
      });

      Notiflix.Notify.success(`Account: "${username}" created`);
    })
    .catch(error => {
      const errorMessage = error.message;
      refs.modalAuth.classList.add('is-hiddenModal');
      document.body.classList.remove('modal-open');
      refs.modalAuth.classList.remove('is-hidden');
      onSignUpBtn();
      Notiflix.Notify.failure(errorMessage);
    });
};

export const signIn = (email, password) => {
  signInWithEmailAndPassword(auth, email, password)
    .then(userCredential => {
      const user = userCredential.user;

      const lastLoginDate = new Date();
      update(ref(database, '/users' + user.uid), {
        last_login: lastLoginDate,
      });
      readShoppingList();
      renderCardOfBooks();

      Notiflix.Notify.success(` Welcome ${userName}!`);
    })
    .catch(error => {
      refs.modalAuth.classList.add('is-hiddenModal');
      document.body.classList.remove('modal-open');
      refs.modalAuth.classList.remove('is-hidden');
      onSignUpBtn();
      Notiflix.Notify.failure('failed to login');
    });
};

export const singOutFunction = () => {
  const isAuth = localStorage.getItem('user-data');
  signOut(auth)
    .then(() => {
      localStorage.removeItem('idBooks');
      if (isAuth) {
        Notiflix.Notify.info('See you soon');
      }
      refs.modalAuth.classList.add('is-hiddenModal');
      document.body.classList.remove('modal-open');
      refs.modalAuth.classList.remove('is-hidden');
      onSignUpBtn();
      if (window.location.href.includes('shopping-list.html')) {
        window.location.href = 'https://book.p.goit.global/';
      }
    })
    .catch(error => {
      Notiflix.Notify.failure(error);
    });
};
